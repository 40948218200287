
p {
  padding: 0;
  margin: 0;
}

.Application {
  background-color: #f3f3f3;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.App-background {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: 100%;
  bottom: 0;
  /* font-size: calc(8px + 2vmin); */
}

#description {
  position: absolute;
  top: 14vh;
  left: 8%;
  color: #000000;
  width: 100vw;
  height: 2vh;
  z-index: 5;

  font-family: 'IBM Plex Sans',
  sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3vh;
  line-height: 1vh;
}

.Search {
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 80%;
  width: 110%;
  top: 92%;
  
}

input {
  background: #E4E4E4;
  height: 8%;
  width: 50%;
  position: relative;
  top: 10%;
  left: 4.5%;
  border-radius: 20px;
  padding-left: 5%;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */
  border-style: hidden;
  vertical-align: middle;
  margin-bottom: 0%;
  

  font-family: 'IBM Plex Sans',
  sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 3vh;
}

#SearchButton {
  background: #ffffff;
  height: 9.75%;
  width: 20%;
  position: relative;
  top: 10%;
  left: 8%;
  border-radius: 20px;
  padding-left: 5%;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */
  border-style: hidden;
  vertical-align: middle;


  font-family: 'IBM Plex Sans',
    sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 3vh;
}

.location-date {
  height: 20%;
  width: 100%;
  position: absolute;
  top: 9%;
}

#location {
  color: #000000;
  height: 100%;
  width: 100%;
  text-align: left;

  position: absolute;
  left: 10%;
  font-family: 'IBM Plex Sans',
  sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2vh;
  line-height: 1vh;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

#date {
  color: #000000;
  height: 100%;
  width: 100%;
  text-align: right;

  position: absolute;
  right: 10%;
  font-family: 'IBM Plex Sans',
  sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2vh;
  line-height: 1vh;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}


#panel-background {
  display: block;
  background: #73BCFF;
  background-size: cover;
  height: 40%;
  width: 90%;
  position: absolute;
  top: 12%;
  left: 5%;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
}

#panel-background-filter {
  display: block;
  background: rgb(1, 62, 119);
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 20px;
  padding: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  filter: brightness;
  opacity: 0;
}


#temp {
  color: #000000;
  height: 100%;
  width: 100%;
  align-items: left;
  margin: 0%;
  z-index: 5;

  position: absolute;
  top: 8%;
  left: 8%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10vh;
  line-height: 76px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

/* #rain-texture {
  background-image: url(./assets/rain-texture2.png);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 1;
  mix-blend-mode: overlay;
} */

.Clouds1 {
  background-image: url(./assets/Cloud2.png);
  height: 100%;
  position: relative;
  top: 20%;
  opacity: 1;
  /* mix-blend-mode: overlay; */
  transform: scale(1.5);
}

.Clouds2 {
  background-image: url(./assets/Cloud3.png);
  height: 100%;
  position: relative;
  top: -100%;
  left: -30%;
  /* mix-blend-mode: overlay; */
  width: 200%;
  opacity: 1;
}

.Clouds3 {
  background-image: url(./assets/Cloud4.png);
  height: 100%;
  position: relative;
  top: -200%;
  left: -150%;
  /* mix-blend-mode: overlay; */
  width: 200%;
  opacity: 1;
}

.Clouds4 {
  background-image: url(./assets/Cloud5.png);
  height: 100%;
  position: relative;
  top: -300%;
  left: -80%;
  /* mix-blend-mode: overlay; */
  width: 200%;
  opacity: 0;
  visibility: hidden;
}

.Mist {
  background-image: url(./assets/Mist.png);
  height: 100%;
  position: relative;
  top: -400%;
  left: -80%;
  /* mix-blend-mode: color-dodge; */
  width: 200%;
  opacity: 1;
  visibility: visible;
}

.Panel {
  border-radius: 20px;
}

#clear-panel {
  display: flex;
  background: #92E0FF;
  background-size: cover;
  height: 12%;
  width: 90%;
  position: absolute;
  top: 54%;
  left: 5%;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

#clear-footer {
  height: 40%;
  width: 80%;
  position: absolute;
  top: 3vh;
  left: 0%;
  border-radius: 0 0 20px 20px;
  padding-left: 8vw;

  font-family: 'IBM Plex Sans',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vh;
  line-height: 3vh;
  vertical-align: center;
}

.MarqueeRow {
  background: transparent;
  position: absolute;
  /* background-repeat: repeat-x; */
  top: 40%;
  left: 0%;
  width: 100%;
  height: 100vh;
  border-radius: 20px;
  overflow: hidden;
  /* transform: scale(3); */
}


.daily-background {
  background: #ffffff;
  background-size: cover;
  height: 30%;
  width: 90%;
  position: absolute;
  top: 68%;
  left: 5%;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 0%;
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.DaysofWeek {
  /* background-color: #233333; */
  background-color: transparent;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 25%;
  left: 0%;
  text-align: center;
}

.Divider2 {
  margin: 0;
  position: absolute;
  top: 30%;
  left: 0%;
  color: #000000;
  width: 100vw;
  transform: translateX(0%);
  transition: 0.2s 0.2s ease-in-out;
  -webkit-transition: 0.2s 0.2s ease-in-out;
  opacity: 1;
  text-align: center;
  transform: scaleX(1.8);
}

#days-footer {
  height: 40%;
  width: 80%;
  position: absolute;
  top: 55%;
  left: 0%;
  border-radius: 0 0 20px 20px;
  padding-left: 8vw;

  font-family: 'IBM Plex Sans',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vh;
  line-height: 3vh;
  vertical-align: center;
}

#Circle {
  background-color: #FFC75A;
  height: 30%;
  width: 20%;
  position: absolute;
  top: 10%;
  left: 3%;
  border-radius: 100px;
}

/* Today Info */
.TodayInfo {
  position: absolute;
  top: -200%;
  left: 10%;
  height: 100%;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Subhead {
  position: absolute;
  top: 0%;
  left: 10%;
  color: #000000;

  font-family: 'IBM Plex Sans',
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2vh;
  line-height: 4vh;
}

.HiLow {
  position: absolute;
  top: 100%;
  left: 0%;
  color: #000000;
  width: 100vw;

  font-family: 'IBM Plex Sans',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 7vh;
  line-height: 4vh;
}

 .Divider {
  margin: 0;
  position: absolute;
  top: 20%;
  left: 0%;
  color: #000000;
  width: 100vw;
  transform-origin: 50vw;
  transform: translateX(0%);
  transition: 0.2s 0.2s ease-in-out;
  -webkit-transition: 0.2s 0.2s ease-in-out;
  opacity: 0;
  text-align: center;
}

/* .Icons {
  margin: 0;
  position: absolute;
  top: 0%;
} */

#weatherbee-logo {
  background-image: url(./assets/weatherbee-logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 40%;
  width: 100%;
  position: absolute;
  top: 210%;
  left: 6%;
}

/* Modules */

.NewsPanel {
  background-color: rgb(255, 236, 172);
  background-size: cover;
  height: auto;
  width: 90%;
  position: relative;
  top: 0%;
  left: 5%;
  border-radius: 20px;
  padding: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

img {
  background-color: transparent;
  background-size: cover;
  height: auto;
  width: 100%;
  position: relative;
  top: 0%;
  left: 0%;
  border-radius: 20px 20px 0px 0px;
  padding: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
  margin-bottom: 0%;
}

#news-description {
  color: rgb(0, 0, 0);
  width: 84%;
  height: 20%;
  position: relative;
  top: 5%;
  left: 0%;
  padding-top: 5%;
  padding-bottom: 10%;
  padding-left: 6vw;
  margin: 2%;

  font-family: 'IBM Plex Sans',
  sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.3vh;
  line-height: 3vh;
}

#ow-bg {
  background-color: #000000;
  padding: 10%;
  border-radius: 20px;
  height: 1%;
  width: 70%;
  position: relative;
  left: 5%;
  top: 0%;
  margin-top: 15px;
}

#openWeather {
  background-color: #000000;
  background-image: url(./assets/openweather_logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 500%;
  width: 80%;
  position: relative;
  top: -21%;
  left: 0%;
  padding: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
  text-align: center;
  /* transform: scale(0.5); */
}

#ds-bg {
  background-color: #ffffff;
  padding: 5%;
  border-radius: 20px;
  height: 3%;
  width: 80%;
  position: relative;
  left: 5%;
  top: 0%;
  margin-top: 15px;
}

#darksky {
  background-color: #ffffff;
  background-image: url(./assets/darksky_logo.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 50px;
  width: 80%;
  position: relative;
  top: 0%;
  left: 0%;
  padding: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
}

#aq-bg {
  background-color: #ffffff;
  padding: 10%;
  border-radius: 20px;
  height: 3%;
  width: 70%;
  position: relative;
  left: 5%;
  top: 0%;
  margin-top: 15px;
}

#airqualityindex {
  background-color: #ffffff;
  background-image: url(./assets/aqi_logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 190%;
  width: 80%;
  position: relative;
  top: -18%;
  left: 0%;
  padding-left: 22%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
  /* transform: scale(0.5); */

  font-family: 'IBM Plex Sans',
  sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.3vh;
  line-height: 6vh;
  text-align: center;
}

#n-bg {
  background-color: #ffffff;
  padding: 10%;
  border-radius: 20px;
  height: 3%;
  width: 70%;
  position: relative;
  left: 5%;
  top: 0%;
  margin-top: 15px;
}

.APIS {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  top: 0%;
  height: 110%;
  width: 100%;

}

#newsapi {
  /* display: flex;
  flex-direction: column; */
  background-color: #ffffff;
  background-image: url(./assets/news-api_logo.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 270%;
  width: 80%;
  position: relative;
  top: -32%;
  left: -6%;
  padding-left: 3%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
  /* transform: scale(0.5); */

  font-family: 'IBM Plex Sans',
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.3vh;
  line-height: 9.5vh;
  text-align: center;
}

#w-bg {
  background-color: transparent;
  border-radius: 20px;
  height: 3%;
  width: 100%;
  position: relative;
  left: 5%;
  top: 0%;
  margin-top: 100px;
}

#weatherbee-footer {
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  height: 300%;
  width: 90%;
  position: relative;
  top: -28%;
  left: 0%;
  padding-top: 0%;
  -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  opacity: 1;
  text-align: center;
  /* transform: scale(0.5); */

  font-family: 'IBM Plex Sans',
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2vh;
  line-height: 8vh;
}

.FooterFlex {
  position: absolute;
  top: 0%;
  height: 130%;
  width: 100%;
  margin-top: 15px;
}

