
.Icons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 24vh;
    left: 10%;
    transition: 0.2s 0.2s ease-in-out;
    opacity: 0;
}

.Sunrise {
    display: flex;
    position: absolute;
    top: 1vh;
    left: 0%;
    padding-top: 0%;
    /* background-color: rgb(255, 183, 0); */
    border-radius: 200px;
    height: 6%;
    width: 100%;
}

.SunriseCopy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -1vh;
    left: 7vw;
    color: #000000;
    width: 100vw;

    font-family: 'IBM Plex Sans',
        sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vh;
    line-height: 4vh;
}

.Sunrise h2 {
    position: relative;
    top: -10%;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2vh;
}

.Sunrise h1 {
    position: relative;
    top: -4vh;
}

.SunriseArrow {
    position: absolute;
    top: 40%;
}

.Sunset {
    display: flex;
    position: absolute;
    top: 15vh;
    left: 0%;
    /* background-color: rgb(231, 134, 255); */
    border-radius: 200px;
    height: 6%;
    width: 100vw;
}

.SunsetCopy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -1vh;
    left: 7vw;
    color: #000000;
    width: 100vw;

    font-family: 'IBM Plex Sans',
        sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vh;
    line-height: 4vh;
}

.Sunset h2 {
    position: relative;
    top: -10%;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2vh;
}

.Sunset h1 {
    position: relative;
    top: -4vh;
}

.SunsetArrow {
    position: absolute;
    top: -30%;
}

.Rain {
    display: flex;
    position: absolute;
    top: 28vh;
    left: 0%;
    height: 6%;
    width: 100vw;
    padding-left: 4vw;
}

.RainDrop {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-left: -3%;
    left: -2%;
}

.RainCopy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0vh;
    left: 7vw;
    color: #000000;
    width: 100vw;

    font-family: 'IBM Plex Sans',
        sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vh;
    line-height: 4vh;
}

.RainCopy h2 {
    position: relative;
    top: -10%;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2vh;
    width: 100vw;
}

.RainCopy h1 {
    position: relative;
    top: -4vh;
}

.Humidity {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 44vh;
    left: 0%;
    padding-left: 0%;
    width: 15vw;
    height: 100%;
}

.HumidityCopy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -2vh;
    left: 7vw;
    color: #000000;
    width: 100vw;

    font-family: 'IBM Plex Sans',
        sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vh;
    line-height: 4vh;
}

.HumidityCopy h2 {
    position: relative;
    top: -10%;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2vh;
    width: 100vw;
}

.HumidityCopy h1 {
    position: relative;
    top: -37%;
}

.RainDroplet {
    position: absolute;
    top: 0%;
}

.Wind {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 28vh;
    left: 0%;
    padding-top: 20%;
    padding-left: 0;

    stroke-dasharray: 50;
    animation: dash 15s ease-in-out infinite reverse;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 1000;
        }
    }

.WindCopy {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -7vh;
        left: 7vw;
        color: #000000;
        width: 100vw;

        font-family: 'IBM Plex Sans',
            sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1.75vh;
        line-height: 4vh;
    }

.WindCopy h2 {
        position: relative;
        top: 5vh;
        text-transform: uppercase;
        font-weight: 200;
        font-size: 2vh;
        width: 100vw;
    }

.WindCopy h1 {
        position: relative;
        top: 0vh;
    }
    

/* UV Index Icon */

.UV {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 25vh;
    left: 0%;
    padding-left: 0%;
    width: 15vw;
    height: 100%;
}

.UVRings {
    display: flex;
    position: relative;
}

.OuterCircle {
    position: relative;
    top: 0%;
    left: 10%;
    padding-top: 20%;
}

.InnerCircle {
    position: absolute;
    top: 0%;
    border-radius: 100%;
    width: 30px;
    height: 30px;
}

.UVCopy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -4vh;
    left: 7vw;
    color: #000000;
    width: 100vw;

    font-family: 'IBM Plex Sans',
        sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vh;
    line-height: 4vh;
}

.UVCopy h2 {
    position: relative;
    top: 5vh;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2vh;
    width: 100vw;
}

.UVCopy h1 {
    position: relative;
    top: 0vh;
}


.AQ {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 25vh;
    left: 0%;
    padding-left: 0%;
    width: 15vw;
    height: 100%;

    stroke-dasharray: 200;
    animation: dash 30s ease-in-out infinite alternate;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 1000;
        }
}

.AirOuterCircle {
    position: relative;
    top: 0%;
    left: 0%;
    padding-top: 20%;
    scale: 0.5;
}


.AirQualityCopy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -4vh;
    left: 7vw;
    color: #000000;
    width: 100vw;

    font-family: 'IBM Plex Sans',
        sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vh;
    line-height: 4vh;
}

.AirQualityCopy h2 {
    position: relative;
    top: 5vh;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2vh;
    width: 100vw;
}

.AirQualityCopy h1 {
    position: relative;
    top: 0vh;
}

.icon-text {
    margin-left: 3%;

    font-family: 'IBM Plex Sans',
    sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2vh;
    line-height: 3vh;
    vertical-align: center;
}

.umbrella-icon-white {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 20%;
    transform: scale(1.2);
    opacity: 0;
}

/* .umbrella-text {
} */

.sweater-icon-white {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 20%;
    transform: scale(1.2);
    opacity: 0;
}

.scarf-icon-white {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 20%;
    transform: scale(1.2);
    opacity: 0;
    background-repeat: no-repeat;
}